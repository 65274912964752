<template>
  <div>
    <div v-show="processStep === 0">
      <KTPortlet title="Check if a domain is available / Register a new domain">
        <template slot="body">
          <Loader v-if="tldPricing === null" :inContent="true"
            >Loading...</Loader
          >
          <b-form v-else @submit="onSubmit">
            <b-row>
              <b-col>
                <b-form-group
                  label="Domain"
                  description="What is the domain name you'd like to search for?"
                >
                  <b-form-input
                    v-model="form.domain"
                    type="text"
                    required
                    placeholder="Enter domain"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="TLD (Top Level Domain)"
                  description="What TLD should the domain be under"
                >
                  <b-form-select
                    v-model="form.tld"
                    :options="selectList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Ready?" description>
                  <b-button
                    style="margin-right: 20px;"
                    type="submit"
                    variant="primary"
                    >Search</b-button
                  >
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Show self hosted DNS pricing"
                  description="You have your own DNS servers"
                >
                  <b-checkbox v-model="form.showNoDNS" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Auto select on search"
                  description="On a successfuly search, we'll select the domains checkbox"
                >
                  <b-checkbox v-model="form.autoRegSearched" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </KTPortlet>
      <KTPortlet
        v-if="domainsList !== null"
        :title="
          `Select the domains to register ${
            (domainsToRegister || []).length > 0
              ? `(${domainsToRegister.length} domains)`
              : ''
          }`
        "
      >
        <template slot="body">
          <div>
            <v-data-table
              v-model="domainsToRegister"
              show-select
              :items-per-page="perPage"
              :page="currentPage"
              :items="domainsList"
              :headers="tableFields"
              hide-default-footer
              item-key="Domain"
            >
              <!-- <template v-slot:item.data-table-select="{ item }">
              <template v-if="item.Status === 1">
                <v-checkbox
                  :checked="selectedDomainsLS.indexOf(item.Domain) >=0"
                  @change="x=>DomainRegSelectionChange(item,x)"
                />
              </template>
              </template>-->
              <template v-slot:item.Domain="{ item }">
                {{ item.Domain }}
                <v-chip
                  v-if="item.premiumDomain"
                  class="ma-2"
                  :style="`background: var(--warning); color: #fff;`"
                  >Premium Domain</v-chip
                >
                <!-- <span v-if="item.Status === 0">
                <b-button
                  style="margin-right: 20px;"
                  @click="SearchAllVarientsOfADomain(item.domainName)"
                  variant="primary"
                >Search All</b-button>
                </span>-->
              </template>
              <template v-slot:item.Status="{ item }">
                <v-chip
                  class="ma-2"
                  :style="
                    `background: var(--${
                      item.Status === -2
                        ? 'danger'
                        : item.Status === -1
                        ? 'gray'
                        : item.Status === 1
                        ? 'success'
                        : 'danger'
                    }); color: #fff;`
                  "
                >
                  <v-icon left color="#fff">{{
                    item.Status === -1
                      ? "refresh"
                      : item.Status === 1
                      ? "check"
                      : "close"
                  }}</v-icon>
                  {{
                    item.Status === -1
                      ? "Searching..."
                      : item.Status === 1
                      ? "Available"
                      : item.Status === -2
                      ? "Not a valid domain name"
                      : "Unavailable"
                  }}
                </v-chip>
              </template>
              <template v-slot:item.Actions="{ item }">
                <div
                  v-if="
                    item.Status === 0 &&
                      varientsSearched.indexOf(item.domainName) < 0
                  "
                >
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    @click="SearchAllVarientsOfADomain(item.domainName)"
                  >
                    <v-icon dark>search</v-icon>
                  </v-btn>
                </div>
              </template>

              <template v-slot:item.tldPricing="{ item, header }">
                <div
                  v-if="
                    $tools.isNullOrUndefined(item.tldPricing) ||
                      item.Status !== 1
                  "
                ></div>
                <div
                  v-else-if="
                    $tools.isNullOrUndefined(
                      item.tldPricing[
                        getTldServiceKeyFromTLDString(header.text)
                      ]
                    )
                  "
                >
                  NU
                </div>
                <div
                  v-else-if="
                    item.tldPricing[getTldServiceKeyFromTLDString(header.text)]
                      .registration ==
                      item.tldPricing[
                        getTldServiceKeyFromTLDString(header.text)
                      ].renewal
                  "
                >
                  {{
                    item.tldPricing[getTldServiceKeyFromTLDString(header.text)]
                      .registrationStrM
                  }}
                </div>
                <div v-else>
                  <div>
                    First year:
                    {{
                      item.tldPricing[
                        getTldServiceKeyFromTLDString(header.text)
                      ].registrationStrM
                    }}
                  </div>
                  <div>
                    Following years:
                    {{
                      item.tldPricing[
                        getTldServiceKeyFromTLDString(header.text)
                      ].renewalStrM
                    }}
                  </div>
                </div>
              </template>

              <template v-slot:item.otherPricing="{ item, header }">
                <div
                  v-if="
                    !$tools.isNullOrUndefined(item.otherPricing) &&
                      item.Status === 1
                  "
                >
                  {{
                    item.otherPricing[
                      GetAdditionalFieldProp(header.text) + "Str"
                    ]
                  }}
                </div>
              </template>
            </v-data-table>
            <b-pagination
              v-if="domainsList.length > perPage"
              align="center"
              v-model="currentPage"
              :total-rows="domainsList.length"
              :per-page="perPage"
            ></b-pagination>
            <div
              v-if="!$tools.isNullOrUndefined(tldServices)"
              style="margin-top:40px;"
            >
              <h6>DNS Types</h6>
              <div
                v-for="tldItem in Object.keys(tldServices)"
                v-bind:key="tldItem"
              >
                <div v-if="tldItem !== 'noDNS' || form.showNoDNS">
                  <b>{{ tldServices[tldItem].short }}</b>
                  : {{ tldServices[tldItem].long }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              !$tools.isNullOrUndefined(domainsToRegister) &&
                domainsToRegister.length > 0
            "
            style="width: 100%; margin-top: 40px; text-align: center;"
          >
            <b-button
              style="margin-right: 20px;"
              @click="StartRegistration"
              variant="primary"
              >Start Registration</b-button
            >
          </div>
        </template>
      </KTPortlet>
    </div>
    <div v-show="processStep === 1">
      <div
        class="kt-wizard-v4"
        id="kt_wizard_v4"
        data-ktwizard-state="step-first"
      >
        <!--begin: Form Wizard Nav -->
        <div class="kt-wizard-v4__nav">
          <div
            class="kt-wizard-v4__nav-items kt-wizard-v4__nav-items--clickable"
          >
            <!--doc: Replace A tag with SPAN tag to disable the step link click -->
            <div
              class="kt-wizard-v4__nav-item"
              data-ktwizard-type="step"
              data-ktwizard-state="current"
            >
              <div class="kt-wizard-v4__nav-body">
                <div class="kt-wizard-v4__nav-number">1</div>
                <div class="kt-wizard-v4__nav-label">
                  <div class="kt-wizard-v4__nav-label-title">
                    Domain Packages
                  </div>
                  <div class="kt-wizard-v4__nav-label-desc">
                    Select the domain DNS server
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
              <div class="kt-wizard-v4__nav-body">
                <div class="kt-wizard-v4__nav-number">2</div>
                <div class="kt-wizard-v4__nav-label">
                  <div class="kt-wizard-v4__nav-label-title">
                    Add-on services
                  </div>
                  <div class="kt-wizard-v4__nav-label-desc">
                    Add additional services to your domain
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
              <div class="kt-wizard-v4__nav-body">
                <div class="kt-wizard-v4__nav-number">3</div>
                <div class="kt-wizard-v4__nav-label">
                  <div class="kt-wizard-v4__nav-label-title">
                    Domain Configurations
                  </div>
                  <div class="kt-wizard-v4__nav-label-desc">
                    Lets set the initial domain config
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
              <div class="kt-wizard-v4__nav-body">
                <div class="kt-wizard-v4__nav-number">4</div>
                <div class="kt-wizard-v4__nav-label">
                  <div class="kt-wizard-v4__nav-label-title">
                    Order Confirmation
                  </div>
                  <div class="kt-wizard-v4__nav-label-desc">
                    Review and Register
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Form Wizard Nav -->

        <div class="kt-portlet">
          <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="kt-grid">
              <div
                class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper"
              >
                <!--begin: Form Wizard Form-->
                <form class="kt-form" id="kt_form">
                  <!--begin: Form Wizard Step 1-->
                  <div
                    class="kt-wizard-v4__content"
                    data-ktwizard-type="step-content"
                    data-ktwizard-state="current"
                  >
                    <div class="kt-heading kt-heading--md">
                      Confirm the DNS type for each domain
                    </div>
                    <v-data-table
                      v-if="processStep === 1"
                      :items-per-page="domainsToRegister.length + 1"
                      :items="domainsToRegister"
                      :headers="wizardStep1TableFields"
                      hide-default-footer
                      item-key="Domain"
                    >
                      <template v-slot:item.Domain="{ item }">
                        {{ item.Domain }}
                        <v-chip
                          v-if="item.premiumDomain"
                          class="ma-2"
                          :style="`background: var(--warning); color: #fff;`"
                          >Premium Domain</v-chip
                        >
                      </template>
                      <template v-slot:item.Package="{ item }">
                        <b-form-select
                          @change="x => DomainPackageChange(item, x)"
                          v-model="domainsToRegisterPackages[item.Domain]"
                          :options="PackageSelectList"
                        ></b-form-select>

                        <!-- <div v-if="$tools.isNullOrUndefined(item.tldPricing) || item.Status !== 1"></div>
                        <div
                          v-else-if="item.tldPricing[getTldServiceKeyFromTLDString(header.text)].registration == item.tldPricing[getTldServiceKeyFromTLDString(header.text)].renewal"
                        >{{item.tldPricing[getTldServiceKeyFromTLDString(header.text)].registrationStrM}}</div>
                        <div v-else>
                          <div>First year: {{item.tldPricing[getTldServiceKeyFromTLDString(header.text)].registrationStrM}}</div>
                          <div>Following years: {{item.tldPricing[getTldServiceKeyFromTLDString(header.text)].renewalStrM}}</div>
                        </div>-->
                      </template>
                      <template v-slot:item.tldPricing="{ item, header }">
                        <div
                          v-if="
                            $tools.isNullOrUndefined(item.tldPricing) ||
                              item.Status !== 1
                          "
                        ></div>
                        <div
                          v-else-if="
                            $tools.isNullOrUndefined(
                              item.tldPricing[
                                getTldServiceKeyFromTLDString(header.text)
                              ]
                            )
                          "
                        >
                          NU
                        </div>

                        <v-chip
                          v-else-if="
                            item.selectedPackage ===
                              getTldServiceKeyFromTLDString(header.text)
                          "
                          class="ma-2"
                          :style="
                            `background: var(--${
                              item.selectedPackage === 'noDNS'
                                ? 'primary'
                                : 'success'
                            }); color: #fff;`
                          "
                          >{{
                            item.tldPricing[
                              getTldServiceKeyFromTLDString(header.text)
                            ].registrationStrM
                          }}</v-chip
                        >
                        <div v-else>
                          {{
                            item.tldPricing[
                              getTldServiceKeyFromTLDString(header.text)
                            ].registrationStrM
                          }}
                        </div>
                      </template>
                    </v-data-table>
                    <div
                      v-if="!$tools.isNullOrUndefined(tldServices)"
                      style="margin-top:40px;"
                    >
                      <h6>DNS Types</h6>
                      <div
                        v-for="tldItem in Object.keys(tldServices)"
                        v-bind:key="tldItem"
                      >
                        <div v-if="tldItem !== 'noDNS' || form.showNoDNS">
                          <b>{{ tldServices[tldItem].short }}</b>
                          : {{ tldServices[tldItem].long }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Form Wizard Step 1-->

                  <!--begin: Form Wizard Step 2-->
                  <div
                    class="kt-wizard-v4__content"
                    data-ktwizard-type="step-content"
                  >
                    <div class="kt-heading kt-heading--md">
                      What additional services should we setup for the domains
                    </div>
                    <template
                      v-if="
                        !$tools.isNullOrUndefined(RegWizard) &&
                          RegWizard.currentStep === 2
                      "
                    >
                      <v-data-table
                        :items-per-page="domainsToRegister.length + 1"
                        :items="domainsForAddonServicesItems"
                        :headers="wizardStep2TableFields"
                        hide-default-footer
                        item-key="Domain"
                      >
                        <template v-slot:item.Domain="{ item }">
                          {{ item.Domain }}
                          <v-chip
                            v-if="item.premiumDomain"
                            class="ma-2"
                            :style="`background: var(--warning); color: #fff;`"
                            >Premium Domain</v-chip
                          >
                        </template>
                        <template v-slot:item.Addon="{ item, header }">
                          <b-form-select
                            @change="
                              x =>
                                (domainsForAddonServicesItemsSelected[
                                  item.Domain
                                ][header.key] = x)
                            "
                            :multiple="header.type === 'multiple'"
                            v-model="
                              domainsForAddonServicesItemsSelected[item.Domain][
                                header.key
                              ]
                            "
                            :options="getwizardStep2AddonServices(header.key)"
                          ></b-form-select>
                        </template>
                      </v-data-table>
                    </template>
                  </div>
                  <!--end: Form Wizard Step 2-->

                  <!--begin: Form Wizard Step 3-->
                  <div
                    class="kt-wizard-v4__content"
                    data-ktwizard-type="step-content"
                  >
                    <div class="kt-form__section kt-form__section--first">
                      <div
                        class="kt-wizard-v4__review"
                        style="padding-top: 0 !important;"
                        v-if="
                          !$tools.isNullOrUndefined(RegWizard) &&
                            RegWizard.currentStep === 3
                        "
                      >
                        <div
                          v-for="domain in domainsToRegister"
                          v-bind:key="domain.Domain"
                          style="margin-bottom: 100px;"
                          class="kt-wizard-v4__review-item"
                        >
                          <div
                            style="margin-top: 20px;"
                            class="row"
                            v-if="
                              domainsToRegisterPackages[domain.Domain] ===
                                'noDNS'
                            "
                          >
                            <div class="col-xl-6">
                              <div class="kt-heading kt-heading--sm">
                                {{ domain.Domain }} - NS Configuration
                              </div>
                              As you have decided to use your own DNS
                              infrastructure, we just need your NS details to
                              setup your domain. <br />This information should
                              be provided from your current DNS provider
                              <br />
                              <br />
                              <div class="form-group">
                                <label>NS1 (Name server) config</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="NS1"
                                  value="ns1.domainprovider.net"
                                  v-model="domain.configuration['ns1']"
                                />
                              </div>
                              <div class="form-group">
                                <label>NS2 (Name server) config</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="NS2"
                                  value="ns2.domainprovider.net"
                                  v-model="domain.configuration['ns2']"
                                />
                              </div>
                              <div class="form-group">
                                <label>NS3 (Name server) config</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="NS3"
                                  value="ns3.domainprovider.net"
                                  v-model="domain.configuration['ns3']"
                                />
                              </div>
                              <div class="form-group">
                                <label>NS4 (Name server) config</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="NS4"
                                  value="ns4.domainprovider.net"
                                  v-model="domain.configuration['ns4']"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="row" style="margin-top: 20px;">
                              <div class="col-xl-12">
                                <div class="kt-heading kt-heading--sm">
                                  {{ domain.Domain }} - DNS Records
                                </div>
                                <div>
                                  Extra DNS Records to add (You can leave this
                                  blank unless you specifically need to enter
                                  something)
                                </div>
                                <v-data-table
                                  :items-per-page="
                                    domain.configuration['dns'].length + 1
                                  "
                                  :items="domain.configuration['dns']"
                                  :headers="wizardStep3TableFields"
                                  hide-default-footer
                                  item-key="index"
                                >
                                  <template v-slot:item.record="{ item }">
                                    <b-input
                                      style="width: 500px; display: inline-block;"
                                      @change="
                                        x =>
                                          UpdateConfigItem(
                                            'mail',
                                            'record',
                                            domain.Domain,
                                            item,
                                            x
                                          )
                                      "
                                      v-model="item.record"
                                    ></b-input>
                                    .{{ domain.Domain }}
                                  </template>
                                  <template v-slot:item.type="{ item }">
                                    <b-form-select
                                      @change="
                                        x =>
                                          UpdateConfigItem(
                                            'dns',
                                            'type',
                                            domain.Domain,
                                            item,
                                            x
                                          )
                                      "
                                      v-model="item.type"
                                      :options="DNSRecordTypes"
                                    ></b-form-select>
                                  </template>
                                  <template v-slot:item.value="{ item }">
                                    <b-input
                                      @change="
                                        x =>
                                          UpdateConfigItem(
                                            'dns',
                                            'value',
                                            domain.Domain,
                                            item,
                                            x
                                          )
                                      "
                                      v-model="item.value"
                                    ></b-input>
                                  </template>
                                  <template v-slot:item.Actions="{ item }">
                                    <b-button
                                      variant="danger"
                                      @click="
                                        RemoveConfigRecord('dns', domain, item)
                                      "
                                      >Delete</b-button
                                    >
                                  </template>
                                </v-data-table>
                                <div style="height: 20px;"></div>
                                <b-button
                                  variant="info"
                                  @click="
                                    AddNewConfigRecord('dns', domain, {
                                      type: 'A',
                                      record: '',
                                      value: ''
                                    })
                                  "
                                  >Add Record</b-button
                                >
                              </div>
                            </div>
                            <div
                              style="margin-top: 20px;"
                              class="row"
                              v-if="
                                domainsForAddonServicesItemsSelected[
                                  domain.Domain
                                ].hosting !== ''
                              "
                            >
                              <div class="col-xl-12">
                                <div class="kt-heading kt-heading--sm">
                                  {{ domain.Domain }} - Web Hosting
                                </div>
                                <div
                                  v-if="
                                    domainsForAddonServicesItemsSelected[
                                      domain.Domain
                                    ].hosting === 'shopify'
                                  "
                                >
                                  We have automatically added the DNS records
                                  for your domain. Once the domain has been
                                  created and propogated, you'll be able to add
                                  it to your shopify site.
                                </div>
                                <div
                                  v-else-if="
                                    domainsForAddonServicesItemsSelected[
                                      domain.Domain
                                    ].hosting === 'betterhost'
                                  "
                                >
                                  We will be in touch with you about this - BETA
                                </div>
                                <div v-else>
                                  We will be in touch with you about this - BETA
                                </div>
                              </div>
                            </div>
                            <div
                              style="margin-top: 20px;"
                              class="row"
                              v-if="
                                domainsForAddonServicesItemsSelected[
                                  domain.Domain
                                ].mail !== ''
                              "
                            >
                              <div class="col-xl-12">
                                <div class="kt-heading kt-heading--sm">
                                  {{ domain.Domain }} - Email Hosting
                                </div>
                                <div
                                  v-if="
                                    domainsForAddonServicesItemsSelected[
                                      domain.Domain
                                    ].mail === 'office365'
                                  "
                                >
                                  <v-data-table
                                    :items-per-page="
                                      domain.configuration['mail'].length + 1
                                    "
                                    :items="domain.configuration['mail']"
                                    :headers="wizardStep3TableFieldsMail"
                                    hide-default-footer
                                    item-key="index"
                                  >
                                    <template v-slot:item.address="{ item }">
                                      <b-input
                                        style="width: 500px; display: inline-block;"
                                        @change="
                                          x =>
                                            UpdateConfigItem(
                                              'mail',
                                              'address',
                                              domain.Domain,
                                              item,
                                              x.toLowerCase().trim()
                                            )
                                        "
                                        v-model="item.address"
                                      ></b-input>
                                      @{{ domain.Domain }}
                                      <v-chip
                                        v-if="
                                          mailNamesLocked.indexOf(
                                            item.address.toLowerCase().trim()
                                          ) >= 0
                                        "
                                        class="ma-2"
                                        :style="
                                          `background: var(--danger); color: #fff;`
                                        "
                                        >Unavailable Account Name</v-chip
                                      >
                                    </template>
                                    <template v-slot:item.type="{ item }">
                                      <b-form-select
                                        @change="
                                          x =>
                                            UpdateConfigItem(
                                              'mail',
                                              'type',
                                              domain.Domain,
                                              item,
                                              x
                                            )
                                        "
                                        v-model="item.type"
                                        :options="[
                                          { text: 'Mailbox', value: 'Mailbox' },
                                          {
                                            text: 'Shared Mailbox',
                                            value: 'Shared Mailbox'
                                          }
                                        ]"
                                      ></b-form-select>
                                    </template>
                                    <template v-slot:item.Actions="{ item }">
                                      <b-button
                                        variant="danger"
                                        @click="
                                          RemoveConfigRecord(
                                            'mail',
                                            domain,
                                            item
                                          )
                                        "
                                        >Delete</b-button
                                      >
                                    </template>
                                  </v-data-table>
                                  <div style="height: 20px;"></div>
                                  <b-button
                                    variant="info"
                                    @click="
                                      AddNewConfigRecord('mail', domain, {
                                        address: '',
                                        type: 'Mailbox'
                                      })
                                    "
                                    >Add Record</b-button
                                  >
                                </div>
                                <div v-else>
                                  We will be in touch with you about this - BETA
                                </div>
                              </div>
                            </div>
                            <div
                              style="margin-top: 20px;"
                              class="row"
                              v-if="
                                domainsForAddonServicesItemsSelected[
                                  domain.Domain
                                ].voip !== ''
                              "
                            >
                              <div class="col-xl-12">
                                <div class="kt-heading kt-heading--sm">
                                  {{ domain.Domain }} - VoIP
                                </div>
                                You've requested a VoIP service, so we will
                                setup a single extension that you can use
                                anywhere. <br /><br />
                                <div
                                  v-if="
                                    domainsForAddonServicesItemsSelected[
                                      domain.Domain
                                    ].voip === 'localNumber'
                                  "
                                >
                                  <div class="form-group">
                                    <label
                                      >What is the numerical area code for your
                                      local number? (eg: 031, 011)</label
                                    >
                                    <b-input
                                      type="text"
                                      class="form-control"
                                      style="width: 100px;"
                                      placeholder="031"
                                      @change="
                                        x => (domain.configuration['voip'] = x)
                                      "
                                      v-model="domain.configuration['voip']"
                                    ></b-input>
                                  </div>
                                </div>
                                <div
                                  v-else-if="
                                    domainsForAddonServicesItemsSelected[
                                      domain.Domain
                                    ].voip === 'nationalNumber'
                                  "
                                >
                                  You've requested an 087 national number. This
                                  will be automatically setup.
                                </div>
                                <div v-else>
                                  We will be in touch with you about this - BETA
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Form Wizard Step 3-->

                  <!--begin: Form Wizard Step 4-->
                  <div
                    class="kt-wizard-v4__content"
                    data-ktwizard-type="step-content"
                  >
                    <div class="kt-heading kt-heading--md">
                      Confirm your order
                    </div>
                    <div class="kt-form__section kt-form__section--first">
                      <div
                        class="kt-wizard-v4__review"
                        v-if="
                          !$tools.isNullOrUndefined(RegWizard) &&
                            RegWizard.currentStep === 4
                        "
                      >
                        <div
                          v-for="domain in domainsToRegister"
                          v-bind:key="domain.Domain"
                          class="kt-wizard-v4__review-item"
                        >
                          <div class="kt-wizard-v4__review-title">
                            {{ domain.Domain }}
                          </div>
                          <div class="kt-wizard-v4__review-content">
                            <b-table-simple responsive>
                              <b-thead>
                                <b-tr>
                                  <b-th sticky-column>Service</b-th>
                                  <!-- <b-th>Item</b-th> -->
                                  <b-th>QTY</b-th>
                                  <b-th>Monthly Total</b-th>
                                  <b-th>Annual Total</b-th>
                                </b-tr>
                              </b-thead>
                              <b-tbody
                                v-if="
                                  domainsToRegisterPackages[domain.Domain] ===
                                    'noDNS'
                                "
                              >
                                <b-tr>
                                  <b-th sticky-column>DNS/NS</b-th>
                                  <!-- <b-td></b-td> -->
                                  <b-td>1</b-td>
                                  <b-td></b-td>
                                  <b-td>{{
                                    domain.tldPricing[
                                      domainsToRegisterPackages[domain.Domain]
                                    ].registrationStr
                                  }}</b-td>
                                </b-tr>
                              </b-tbody>
                              <b-tbody v-else>
                                <b-tr>
                                  <b-th sticky-column>DNS/NS</b-th>
                                  <!-- <b-td></b-td> -->
                                  <b-td>1</b-td>
                                  <b-td>{{
                                    domain.tldPricing[
                                      domainsToRegisterPackages[domain.Domain]
                                    ].registrationStrM
                                  }}</b-td>
                                  <b-td>{{
                                    domain.tldPricing[
                                      domainsToRegisterPackages[domain.Domain]
                                    ].renewalStr
                                  }}</b-td>
                                </b-tr>
                                <b-tr
                                  v-for="pricingItem in GetDomainAddonServicePriceOpts(
                                    domain
                                  )"
                                  v-bind:key="pricingItem.index"
                                >
                                  <!-- <b-tr v-for="pricingActual in pricingItem.pricing" v-bind:key="pricingActual.title"> -->
                                  <b-th sticky-column>{{
                                    pricingItem.group.text
                                  }}</b-th>
                                  <!-- <b-td>{{pricingActual.title}}</b-td> -->
                                  <b-td>{{ pricingItem.pricing.length }}</b-td>
                                  <b-td>{{
                                    pricingItem.monthlyCost > 0
                                      ? $tools.formatCurrency(
                                          pricingItem.monthlyCost,
                                          2,
                                          ".",
                                          " ",
                                          "ZAR"
                                        ) + "/pm"
                                      : ""
                                  }}</b-td>
                                  <b-td>{{
                                    pricingItem.annualCost > 0
                                      ? $tools.formatCurrency(
                                          pricingItem.annualCost,
                                          2,
                                          ".",
                                          " ",
                                          "ZAR"
                                        ) + "/pa"
                                      : ""
                                  }}</b-td>
                                </b-tr>
                                <b-tr> </b-tr>
                                <b-tr>
                                  <b-th sticky-column>Total</b-th>
                                  <!-- <b-td></b-td> -->
                                  <b-td></b-td>
                                  <b-td>{{
                                    $tools.formatCurrency(
                                      domain.tldPricing[
                                        domainsToRegisterPackages[domain.Domain]
                                      ].registration +
                                        GetDomainAddonServicePriceOpts(
                                          domain,
                                          true
                                        ),
                                      2,
                                      ".",
                                      " ",
                                      "ZAR"
                                    ) + "/pm"
                                  }}</b-td>
                                  <b-td>{{
                                    $tools.formatCurrency(
                                      domain.tldPricing[
                                        domainsToRegisterPackages[domain.Domain]
                                      ].renewal +
                                        GetDomainAddonServicePriceOpts(
                                          domain,
                                          false,
                                          true
                                        ),
                                      2,
                                      ".",
                                      " ",
                                      "ZAR"
                                    ) + "/pa"
                                  }}</b-td>
                                </b-tr>
                              </b-tbody>
                            </b-table-simple>
                            <!-- <div v-if="domainsToRegisterPackages[domain.Domain] === 'noDNS'">
                            DNS/NS: {{domain.tldPricing[domainsToRegisterPackages[domain.Domain]].registrationStr}} (1 year)
                            </div>
                            <div v-else>
                            DNS/NS: {{domain.tldPricing[domainsToRegisterPackages[domain.Domain]].registrationStrM}} (min commitment 1 year)
                            <br />Phone: +61412345678
                            <br />Email: johnwick@reeves.com
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Form Wizard Step 4-->

                  <!--begin: Form Actions -->
                  <div class="kt-form__actions">
                    <b-button
                      v-if="
                        !$tools.isNullOrUndefined(RegWizard) &&
                          RegWizard.currentStep === 1
                      "
                      class="btn btn-warning btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                      style="margin-right: 20px;"
                      @click="defaultheader()"
                      >Back to search</b-button
                    >
                    <button
                      class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                      data-ktwizard-type="action-prev"
                    >
                      Previous
                    </button>
                    <button
                      v-on:click="submit"
                      class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                      data-ktwizard-type="action-submit"
                    >
                      Submit
                    </button>
                    <button
                      class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                      data-ktwizard-type="action-next"
                    >
                      Next Step
                    </button>
                  </div>
                  <!--end: Form Actions -->
                </form>
                <!--end: Form Wizard Form-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTUtil from "@/assets/js/util";
import KTWizard from "@/assets/js/wizard";
//import Swal from "sweetalert2";
import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader
  },
  watch: {
    /*domainsList() {
      for (let domain of this.domainsList)
        if (
          domain.Status === 1 &&
          this.selectedDomainsLS.indexOf(domain.Domain) < 0
        )
          this.$data.selectedDomainsLS.push(domain.Domain);
    },*/
    domainsToRegister() {
      for (let ix = 0; ix < this.domainsToRegister.length; ix++)
        if (this.domainsToRegister[ix].Status !== 1)
          return this.domainsToRegister.splice(ix, 1);
      /*let selectedDomains = [];
      for (let domain of this.domainsToRegister)
        selectedDomains.push(domain.Domain);

      this.$data.selectedDomainsLS = selectedDomains*/
    }
  },
  data() {
    return {
      onLoadDomainsToSearch: [],
      RegWizard: null,
      domainsToRegister: [],
      domainsToRegisterPackages: {},
      domainsForAddonServicesItems: [],
      domainsForAddonServicesItemsSelected: {},
      domainsToRegisterAddonServices: {},
      wizardStep1DomainsToRegister: [],
      //selectedDomainsLS: [],
      form: {
        domain: "",
        tld: "co.za",
        showNoDNS: false,
        autoRegSearched: true
      },
      processStep: 0,
      currentPage: 1,
      perPage: 50,
      tldPricing: null,
      tldServices: null,
      domainsList: null,
      varientsSearched: [],
      wizardStep1TableBaseFields: [{ text: "Domain", value: "Domain" }],
      wizardStep2TableBaseFields: [{ text: "Domain", value: "Domain" }],
      wizardStep3TableFields: [
        { text: "Record", value: "record" },
        { text: "Type", value: "type" },
        { text: "Value", value: "value" },
        { text: "Actions", value: "Actions" }
      ],
      wizardStep3TableFieldsMail: [
        { text: "Address", value: "address" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "Actions" }
      ],
      wizardStep2AddonServiceGroups: [
        {
          text: "Web Hosting",
          key: "hosting",
          type: "select",
          default: "betterhost"
        },
        {
          text: "Email Hosting",
          key: "mail",
          type: "select",
          default: "office365",
          dynamic: true
        },
        {
          text: "VoIP Service",
          key: "voip",
          type: "select",
          default: "localNumber"
        } /*,
        {
          text: "Marketing",
          key: "marketing",
          type: "multiple",
          default: []
        }*/
      ],
      wizardStep2AddonServices: [
        // {
        //   text: "We Host your site",
        //   key: "betterhost",
        //   group: "hosting",
        //   annualCost: 0,
        //   monthlyCost: 100
        // },
        {
          text: "Woocomerse",
          key: "woocomerce",
          group: "hosting",
          annualCost: 0,
          monthlyCost: 0
        },
        {
          text: "Shopify",
          key: "shopify",
          group: "hosting",
          annualCost: 0,
          monthlyCost: 0
        },
        {
          text: "None",
          key: "",
          group: "hosting",
          annualCost: 0,
          monthlyCost: 0
        },
        {
          text: "Hosted Exchange",
          key: "office365",
          group: "mail",
          annualCost: 0,
          monthlyCost: {
            Mailbox: 150,
            "Shared Mailbox": 50
          },
          dynamic: "{config.address}@{domain.Domain}"
        },
        {
          text: "None",
          key: "",
          group: "mail",
          annualCost: 0,
          monthlyCost: 0
        },
        {
          text: "Local office number",
          key: "localNumber",
          group: "voip",
          annualCost: 0,
          monthlyCost: 50
        },
        {
          text: "087 National number",
          key: "nationalNumber",
          group: "voip",
          annualCost: 0,
          monthlyCost: 150
        },
        {
          text: "None",
          key: "",
          group: "voip",
          annualCost: 0,
          monthlyCost: 0
        },
        {
          text: "Facebook Page",
          key: "facebook",
          group: "marketing",
          annualCost: 0,
          monthlyCost: 0
        },
        {
          text: "Campaign Monitor (Basic)",
          key: "capaignMonitor",
          group: "marketing",
          annualCost: 0,
          monthlyCost: 200
        },
        {
          text: "None",
          key: "",
          group: "marketing",
          annualCost: 0,
          monthlyCost: 0
        }
      ],
      baseTableFields: [
        { text: "Status", value: "Status" },
        { text: "Domain", value: "Domain" }
      ],
      serviceTableFields: [],
      additionalTableFields: [
        { text: "Transfer", value: "otherPricing", key: "transfer" },
        { text: "Redemption", value: "otherPricing", key: "transfer" },
        { text: "Actions", value: "Actions" }
      ],
      DNSRecordTypes: [
        { text: "A", value: "A" },
        { text: "AAAA", value: "AAAA" },
        { text: "MX", value: "MX" },
        { text: "CNAME", value: "CNAME" },
        { text: "TXT", value: "TXT" },
        { text: "SPF", value: "SPF" },
        //{ text: 'SRV', value: 'SRV' },
        { text: "ALIAS", value: "ALIAS" }
        //{ text: 'DDDDDDDDDDDA', value: 'DDDDDDDDDDDA' },
      ],
      mailNamesLocked: [
        "webmaster",
        "serveradmin",
        "admin",
        "hostmaster",
        "domains",
        "abuse",
        "root"
      ]
    };
  },
  methods: {
    GetDomainAddonServicePriceOpts(
      domain,
      returnTotalMonthly = false,
      returnTotalAnnual = false
    ) {
      let listOfPricings = [];
      for (let ix = 0; ix < this.domainsToRegister.length; ix++) {
        if (this.domainsToRegister[ix].Domain !== domain.Domain) continue;

        for (let serviceGroup of this.wizardStep2AddonServiceGroups) {
          if (
            this.domainsForAddonServicesItemsSelected[
              this.domainsToRegister[ix].Domain
            ][serviceGroup.key] === ""
          )
            continue;
          let pricing = [];

          for (let priceObjOfGroup of this.wizardStep2AddonServices) {
            if (priceObjOfGroup.group !== serviceGroup.key) continue;
            if (
              this.domainsForAddonServicesItemsSelected[
                this.domainsToRegister[ix].Domain
              ][serviceGroup.key] !== priceObjOfGroup.key
            )
              continue;

            if (serviceGroup.dynamic === true) {
              for (let innerDomain of this.domainsForAddonServicesItems) {
                if (innerDomain.Domain !== this.domainsToRegister[ix].Domain)
                  continue;

                for (let configItem of innerDomain.configuration[
                  serviceGroup.key
                ]) {
                  let annCost = priceObjOfGroup.annualCost || 0;
                  if (typeof priceObjOfGroup.annualCost === "object") {
                    annCost = priceObjOfGroup.annualCost[configItem.type] || 0;
                  }
                  let monCost = priceObjOfGroup.monthlyCost || 0;
                  if (typeof priceObjOfGroup.monthlyCost === "object") {
                    monCost = priceObjOfGroup.monthlyCost[configItem.type] || 0;
                  }
                  pricing.push({
                    title: this.$tools.StringReplaceWithObject(
                      {
                        config: configItem,
                        domain: this.domainsToRegister[ix]
                      },
                      priceObjOfGroup.dynamic
                    ),
                    qty: 1,
                    annualCost: annCost,
                    monthlyCost: monCost
                  });
                }
              }
            } else {
              pricing.push({
                title: priceObjOfGroup.text,
                qty: 1,
                annualCost: priceObjOfGroup.annualCost,
                monthlyCost: priceObjOfGroup.monthlyCost
              });
            }
          }

          let totalAnu = 0;
          let totalMon = 0;
          for (let priceItem of pricing) {
            totalAnu += priceItem.annualCost;
            totalMon += priceItem.monthlyCost;
          }
          if (totalAnu !== 0 || totalMon !== 0)
            listOfPricings.push({
              index: listOfPricings.length,
              group: serviceGroup,
              pricing: pricing,
              annualCost: totalAnu,
              monthlyCost: totalMon
            });
        }

        break;
      }

      if (returnTotalMonthly) {
        let totalAmt = 0;
        for (let item of listOfPricings) {
          totalAmt += item.monthlyCost;
        }
        return totalAmt;
      }

      if (returnTotalAnnual) {
        let totalAmt = 0;
        for (let item of listOfPricings) {
          totalAmt += item.annualCost;
        }
        return totalAmt;
      }

      return listOfPricings;
    },
    UpdateConfigItem(configType, recordKey, domain, record, value) {
      for (let ix = 0; ix < this.domainsToRegister.length; ix++) {
        if (this.domainsToRegister[ix].Domain !== domain.Domain) continue;
        for (
          let ex = 0;
          ex < this.domainsToRegister[ix].configuration[configType].length;
          ex++
        ) {
          if (
            this.domainsToRegister[ix].configuration[configType][ex].index !==
            record.index
          )
            continue;

          this.domainsToRegister[ix].configuration[configType][ex][
            recordKey
          ] = value;
          return;
        }
      }
    },
    RemoveConfigRecord(configType, domain, record) {
      for (let ix = 0; ix < this.domainsToRegister.length; ix++) {
        if (this.domainsToRegister[ix].Domain !== domain.Domain) continue;
        for (
          let ex = 0;
          ex < this.domainsToRegister[ix].configuration[configType].length;
          ex++
        ) {
          if (
            this.domainsToRegister[ix].configuration[configType][ex].index !==
            record.index
          )
            continue;
          this.domainsToRegister[ix].configuration[configType].splice(ex, 1);
          return;
        }
      }
    },
    AddNewConfigRecord(configType, domain, defaultObj) {
      let heighestIndex = 0;
      for (let ix = 0; ix < this.domainsToRegister.length; ix++) {
        if (this.domainsToRegister[ix].Domain !== domain.Domain) continue;
        for (
          let ex = 0;
          ex < this.domainsToRegister[ix].configuration[configType].length;
          ex++
        ) {
          if (
            this.domainsToRegister[ix].configuration[configType][ex].index >
            heighestIndex
          )
            heighestIndex = this.domainsToRegister[ix].configuration[
              configType
            ][ex].index;
        }
      }
      heighestIndex++;
      for (let ix = 0; ix < this.domainsToRegister.length; ix++) {
        if (this.domainsToRegister[ix].Domain !== domain.Domain) continue;
        this.domainsToRegister[ix].configuration[configType].push({
          index: heighestIndex,
          ...defaultObj
        });
        return;
      }
    },
    getwizardStep2AddonServices(key) {
      let list = [];
      for (let service of this.wizardStep2AddonServices)
        if (service.group == key)
          list.push({ text: service.text, value: service.key });
      return list;
    },
    DomainPackageChange(item) {
      for (let ix = 0; ix < this.domainsToRegister.length; ix++) {
        if (this.$data.domainsToRegister[ix].Domain !== item.Domain) continue;
        this.$data.domainsToRegister[
          ix
        ].selectedPackage = this.domainsToRegisterPackages[item.Domain];
        this.domainsToRegisterPackagesChangeTrig();
        return;
      }
    },
    StartRegistration() {
      this.$data.processStep = 1;
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "BetterWeb" },
        { title: "Domains" },
        { title: "New Domain" },
        { title: "Registration Confirmation" }
      ]);
    },
    GetAdditionalFieldProp(txt) {
      for (let field of this.additionalTableFields)
        if (field.text == txt) return field.key;
      return null;
    },
    getTLDPricingObject(tld) {
      for (let priceObj of this.tldPricing) {
        if (priceObj.tld == tld) return priceObj;
      }
      return null;
    },
    getTldServiceKeyFromTLDString(shortText) {
      for (let service of Object.keys(this.tldServices)) {
        if (shortText == this.tldServices[service].short) return service;
      }
      return null;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.searchForDomain(this.form.domain, this.form.tld);
      //this.$data.form.domain = '';
    },
    SearchAllVarientsOfADomain(domainName) {
      this.$data.varientsSearched.push(domainName);
      for (let tld of this.tldPricing) {
        this.searchForDomain(domainName, tld.tld);
      }
    },
    searchForDomainResponse(data, domain, tld) {
      let self = this;
      for (let ix = 0; ix < self.domainsList.length; ix++) {
        if (
          self.domainsList[ix].domainName === domain &&
          self.domainsList[ix].tld === tld
        ) {
          self.$data.domainsList[ix].Status = data.available ? 1 : 0;
          self.$data.domainsList[ix].StatusMsg = data.msg;
          self.$data.domainsList[ix].eppKey = data.eppKey;
          self.$data.domainsList[ix].premiumDomain = data.premiumDomain;
          let tldPricingObj = self.getTLDPricingObject(tld);
          self.$data.domainsList[ix].tldPricing = {};
          for (let tldPriceO of Object.keys(tldPricingObj.services)) {
            let registration = tldPricingObj.services[tldPriceO].registration;
            let renewal = tldPricingObj.services[tldPriceO].renewal;
            let premium = tldPricingObj.services[tldPriceO].premium;
            if (data.premiumDomain) {
              if (premium > registration) registration = premium;
              if (premium > renewal) renewal = premium;
            }
            self.$data.domainsList[ix].tldPricing[tldPriceO] = {
              registration,
              registrationM: registration / 12,
              registrationStr: self.$tools.formatCurrency(
                registration,
                2,
                ".",
                " ",
                "ZAR"
              ),
              registrationStrM:
                self.$tools.formatCurrency(
                  registration / 12,
                  2,
                  ".",
                  " ",
                  "ZAR"
                ) + "/pm",
              renewal,
              renewalM: renewal / 12,
              renewalStr: self.$tools.formatCurrency(
                renewal,
                2,
                ".",
                " ",
                "ZAR"
              ),
              renewalStrM:
                self.$tools.formatCurrency(renewal / 12, 2, ".", " ", "ZAR") +
                "/pm"
            };
          }
          self.$data.domainsList[ix].otherPricing = {
            transfer: tldPricingObj.transfer,
            transferStr: self.$tools.formatCurrency(
              tldPricingObj.transfer,
              2,
              ".",
              " ",
              "ZAR"
            ),
            redemption: tldPricingObj.redemption,
            redemptionStr: self.$tools.formatCurrency(
              tldPricingObj.redemption,
              2,
              ".",
              " ",
              "ZAR"
            )
          };

          /*self.domainsList[ix].tldPricing = {
                    registration: self.$tools.formatCurrency(tldPricingObj.services[self.getTldServiceKeyFromTLDString(header.text)].registration/12, 2, '.', ' ', 'ZAR')
                  }*/

          if (
            data.available &&
            !data.premiumDomain &&
            self.form.autoRegSearched
          ) {
            self.$nextTick(() => {
              self.$data.domainsToRegister.push(self.domainsList[ix]);
            });
          }
          return;
        }
      }
    },
    searchForDomain(domainF, tldF) {
      let domain = `${domainF}`.trim().toLowerCase();
      let tld = `${tldF}`.trim().toLowerCase();
      if (this.domainsList === null) this.$data.domainsList = [];

      let fullName = `${domain}.${tld}`;
      for (let domain of this.domainsList) {
        if (domain.Domain == fullName) return;
      }

      this.$data.domainsToRegisterPackages[fullName] =
        this.config.self.domains.defaultDNS || Object.keys(this.tldServices)[0];
      this.domainsToRegisterPackagesChangeTrig();
      this.$data.domainsToRegisterAddonServices[
        fullName
      ] = this.GetDefaultAddonServicesObject();
      let domainRegex = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
      if (!domainRegex.test(fullName)) {
        this.$data.domainsList.unshift({
          Status: -2,
          Domain: fullName,
          domainName: domain,
          tld: tld,
          premiumDomain: false,
          StatusMsg: null,
          eppKey: false,
          tldPricing: null,
          otherPricing: null,
          selectedPackage: this.$data.domainsToRegisterPackages[fullName],
          configuration: { dns: [], mail: [] }
        });
        return;
      }

      this.$data.domainsList.unshift({
        Status: -1,
        Domain: fullName,
        domainName: domain,
        tld: tld,
        premiumDomain: false,
        StatusMsg: null,
        eppKey: false,
        tldPricing: null,
        otherPricing: null,
        selectedPackage: this.$data.domainsToRegisterPackages[fullName],
        configuration: { dns: [], mail: [] }
      });
      this.$data.currentPage = 1;
      let self = this;
      this.$cache
        .get(`betterweb:domains-search-${fullName}`)
        .then(cacheOfServices => {
          if (cacheOfServices) {
            return self.searchForDomainResponse(cacheOfServices, domain, tld);
          }
          ApiService.get(`domains/new/${domain}/${tld}`)
            .then(({ data }) => {
              self.$cache.set(`betterweb:domains-search-${fullName}`, data);
              self.searchForDomainResponse(data, domain, tld);
            })
            .catch(({ response }) => {
              self.$log.error(response);
            });
        })
        .catch(x => {
          self.$log.error(x);
          //console.error("Error loading content");
        });
    },
    loadServices(data) {
      this.$data.currentPage = 1;
      this.$data.tldPricing = data.pricing;
      this.$data.tldServices = data.services;
      let tarr = [];
      for (let service of Object.keys(data.services)) {
        tarr.push({
          text: data.services[service].short,
          value: "tldPricing",
          key: service
        });
      }
      this.$data.serviceTableFields = tarr;

      for (let domainToSearch of this.onLoadDomainsToSearch) {
        let splitDomain = domainToSearch.split(".");
        this.searchForDomain(splitDomain[0], splitDomain.splice(1).join("."));
      }
    },
    submit() {},
    defaultheader() {
      this.$data.processStep = 0;
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "BetterWeb" },
        { title: "Domains" },
        { title: "New Domain" }
      ]);
    },
    GetDefaultAddonServicesObject() {
      return {};
    },
    domainsToRegisterPackagesChangeTrig() {
      let listOfDomains = [];
      for (let domain of this.domainsToRegister) {
        if (this.domainsToRegisterPackages[domain.Domain] == "noDNS") continue;
        listOfDomains.push(domain);
      }
      this.$data.domainsForAddonServicesItems = listOfDomains;

      for (let domain of listOfDomains) {
        this.$data.domainsForAddonServicesItemsSelected[domain.Domain] = {};
        for (let group of this.wizardStep2AddonServiceGroups) {
          if (group.type == "mulitple") {
            this.$data.domainsForAddonServicesItemsSelected[domain.Domain][
              group.key
            ] = group.default || [];
          } else
            this.$data.domainsForAddonServicesItemsSelected[domain.Domain][
              group.key
            ] = group.default || null;
        }
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    wizardStep1TableFields() {
      let endFields = [];
      for (let field of this.wizardStep1TableBaseFields) endFields.push(field);
      for (let field of this.serviceTableFields) {
        if (field.key == "noDNS" && !this.form.showNoDNS) continue;
        endFields.push(field);
      }
      endFields.push({ text: "Package", value: "Package" });
      return endFields;
    },
    wizardStep2TableFields() {
      let endFields = [];
      for (let field of this.wizardStep2TableBaseFields) endFields.push(field);
      for (let group of this.wizardStep2AddonServiceGroups) {
        endFields.push({
          text: group.text,
          value: "Addon",
          key: group.key,
          type: group.type
        });
      }
      //      endFields.push({ text: "Package", value: "Package" });
      return endFields;
    },
    tableFields() {
      let endFields = [];
      for (let field of this.baseTableFields) endFields.push(field);
      for (let field of this.serviceTableFields) {
        if (field.key == "noDNS" && !this.form.showNoDNS) continue;
        endFields.push(field);
      }
      for (let field of this.additionalTableFields) endFields.push(field);
      return endFields;
    },
    PackageSelectList() {
      let newOptsList = [];
      for (let field of this.serviceTableFields) {
        if (field.key == "noDNS" && !this.form.showNoDNS) continue;
        newOptsList.push({ value: field.key, text: field.text });
      }
      return newOptsList;
    },
    selectList() {
      if (
        this.$tools.isNullOrUndefined(this.tldPricing) ||
        typeof this.tldPricing != "object" ||
        this.tldPricing.length == undefined
      )
        return [];
      let newOptsList = [];
      for (let tld of this.tldPricing) {
        newOptsList.push({ value: tld.tld, text: tld.tld });
      }
      return newOptsList;
    }
  },
  mounted() {
    let self = this;
    self.defaultheader();
    this.$cache
      .get("betterweb:domains-tlds")
      .then(cacheOfServices => {
        if (cacheOfServices) {
          return self.loadServices(cacheOfServices);
        }
        ApiService.get("domains/tlds")
          .then(({ data }) => {
            self.$cache.set("betterweb:domains-tlds", data);
            self.loadServices(data);
          })
          .catch(({ response }) => {
            self.$log.error(response);
          });
      })
      .catch(x => {
        self.$log.error(x);
        //console.error("Error loading content");
      });

    // Initialize form wizard
    const wizarddd = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    // Validation before going to next page
    wizarddd.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    let historyKeeper = 0;
    wizarddd.on("change", function(/*wizardObj*/) {
      if (wizarddd.currentStep === 2) {
        self.domainsToRegisterPackagesChangeTrig();
        if (self.domainsForAddonServicesItems.length === 0) {
          if (historyKeeper === 1) wizarddd.goNext();
          else wizarddd.goPrev();
        }
      }
      historyKeeper = wizarddd.currentStep;
      setTimeout(function() {
        KTUtil.scrollTop();
      }, 500);
    });

    this.$data.RegWizard = wizarddd;
    /*Swal.fire({
      title: "",
      text: "The application has been successfully submitted!",
      icon: "success",
      confirmButtonClass: "btn btn-secondary"
    });*/
  }
};
</script>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>
